<template>
  <CCard>
    <CCardHeader>
      <div class="card-header-actions">
        <CButton variant="outline" color="success" @click="onReset" ref="reset-button" v-translate
                 translate-context="form" class="mr-2">Reset
        </CButton>
        <CButton color="primary" @click="onSave" ref="save-button">{{ save_text }}</CButton>
      </div>
    </CCardHeader>
    <CCardBody class="p-0">
      <CContainer fluid class="grid-stripped">
        <PFormRow>
          <template v-slot:firstColContent>
            {{ $pgettext('shortener.label', 'Short Url') }}
          </template>
          <template v-slot:secondColContent>
            <CInput
                v-model="form.id"
                ref="form-id"
                :disabled="disableShortEdit"
                :placeholder="$pgettext('shortener.placeholder','Short Url')"
            />
          </template>
        </PFormRow>
        <PFormRow>
          <template v-slot:firstColContent>
            {{ $pgettext('shortener.label', 'Long Url') }}
          </template>
          <template v-slot:secondColContent>
            <CTextarea
                v-model="form.long_url"
                ref="form-long-url"
            />
            <small>{{$pgettext('shortener.helper', 'You can add parameters to the long url with {params}, ex: http://www.google.it/?s={search}')}}</small><br>
            <small>{{$pgettext('shortener.helper', 'In case the short url doesn\'t have the params, the param will be empty')}}</small><br>
            <small>{{ $pgettext('shortener.helper', 'Parameter not in the long url will be ignored') }}</small>
          </template>
        </PFormRow>
      </CContainer>
    </CCardBody>
  </CCard>

</template>

<script>
import PFormRow from "@/components/PFormRow.vue";

export default {
  name: "Form",
  components: {PFormRow},
  props: {
    data: {
      type: Object,
      required: true
    },
    save_text: {
      type: String,
      required: true
    },
    disableShortEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        code: '',
        description: '',
      },
    }
  },
  mounted() {
    this.onReset()
  },
  emits: ['save'],
  methods: {
    onReset() {
      this.form = {...this.data}
    },
    async onSave() {
      this.$emit('save', this.form)
    },
  }
}
</script>

<style scoped>

</style>