<template>
  <div v-if="loaded">
    <h1 class="main-header" v-translate translate-context="form'">Create</h1>
    <Form :data="empty" @save="onSave" :save_text="$pgettext('form','Create')"/>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import {SuccessNotification} from "@/domain/core/exception/exceptions";
import Form from "@/domain/shortener/components/Form.vue";

export default {
  name: 'NewShortUrl',
  components: {Form},
  data() {
    return {
      loaded: false,
      empty: {
        id: '',
        long_url: '',
      }
    }
  },
  async mounted() {
    this.empty.id = await this.getFreeUrl()
    this.loaded = true
  },
  methods: {
    ...mapActions('shortener', [
      'createShort',
      'getFreeUrl'
    ]),
    async onSave(data) {
      await this.createShort(data)
      await this.$router.push({name: 'shortener.list'})
      throw new SuccessNotification(this.$pgettext('shortener', 'Short Url created!'))
    }
  }
}
</script>

<style scoped>

</style>